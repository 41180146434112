import React from 'react';
import { ReactComponent as AppStore } from 'assets/svg/app-store.svg';
import { ReactComponent as PlayStore } from 'assets/svg/play-store.svg';
import { ReactComponent as AppLogo } from 'assets/svg/app-logo.svg';
import { ReactComponent as Twitter } from 'assets/svg/twitter.svg';
import { ReactComponent as Instagram } from 'assets/svg/instagram.svg';
import { ReactComponent as TikTok } from 'assets/svg/tiktok.svg';
import { Link } from 'react-router-dom';
import { POUCHERS_WEBSITE_URL } from 'constants';

export default function Footer() {
  return (
    <footer>
      <div className="bg-[#1D2939] flex flex-col gap-16 py-16">
        <div className="container flex flex-col sm:flex-row justify-between">
          <div className="mb-[32px] sm:mb-0 flex flex-col gap-8">
            <div>
              <AppLogo className="mb-[32px]" />
              <p className="mb-[36px] max-w-[320px] text-white text-16 font-dmsans_r leading-[24px]">
                Take charge with Pouchers—automate bills, track spending, and get virtual cards.
                It’s your all-in-one financial sidekick!
              </p>
            </div>
            <div className='flex flex-col gap-4 text-white'>
              <a
                href="mailto:chat@pouchers.io"
                className="font-dmsans_r font-normal text-14">
                chat@pouchers.io
              </a>
              <a
                href="tel:+2348169677368"
                className="font-dmsans_r font-normal text-14">
                08169677368
              </a>
            </div>
          </div>
          <div>
            <p className="mb-[16px] text-white text-16 font-dmsans_m">Get the app</p>
            <Link to={`${process.env.REACT_APP_POUCHERS_APP_IOS}`} target="_blank">
              <AppStore className="cursor-pointer mb-[16px] w-[135px]" />
            </Link>
            <Link to={`${process.env.REACT_APP_POUCHERS_APP_ANDROID}`} target="_blank">
              <PlayStore className="cursor-pointer w-[135px]" />
            </Link>
          </div>
        </div>
        <nav className="flex flex-col md:flex-row md:mx-auto w-fit pl-4 sm:pl-8 md:pl-0 text-white text-16 font-dmsans_m">
          <a href={`${POUCHERS_WEBSITE_URL}/blog`} className="mr-[32px] mb-[20px] sm:mb-0 hide_tap">
            Blog
          </a>
          <Link to="/terms-of-use" className="mr-[32px] mb-[20px] sm:mb-0 hide_tap">
            Terms of Use
          </Link>
          <Link to="/privacy-policy" className="cursor-pointer hide_tap">
            Privacy Policy
          </Link>
        </nav>
        <div className="container flex flex-col sm:flex-row justify-between items-center pt-[32px] border-t border-t-[#475467]">
          <p className="text-16 mb-[10px] sm:mb-0 font-dmsans_r font-normal text-[#98A2B3]">
            © {new Date().getFullYear()} Pouchers Inc. All rights reserved.
          </p>
          <ul className="flex">
            <li className="mr-[24px]">
              <Link to="https://x.com/mypoucher" target="_blank">
                <Twitter />
              </Link>
            </li>
            <li className="mr-[24px]">
              <Link to="https://www.instagram.com/mypoucher" target="_blank">
                <Instagram />
              </Link>
            </li>
            <li>
              <Link to="https://www.tiktok.com/@mypoucher" target="_blank">
                <TikTok />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
